import { useState, useEffect } from 'react'
import { actions as notyActions } from 'layouts/ErrorBox'
import { h64 } from 'xxhashjs'
import { generateNotyMessage } from './request'
import { USER_ROLES_WITH_PII_NAME_ID_MAP, USER_ROLE_ID_MAP, SITE_ROLE_LIST, ROLE_NAME_ID_MAP } from './constants'

export const isNumeric = str => {
  return !isNaN(parseFloat(str)) && isFinite(str)
}

export const pluralize = (num, singularStr, pluralStr, includeNum = true) => {
  return `${includeNum ? `${num} ` : ''}${num === 0 || num > 1 || !num ? pluralStr : singularStr}`
}

export const capitalize = string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const validatePassword = str => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*?])[A-Za-z\d~!@#$%^&*? ]{8,}/g
  return !!str.match(regex)
}

export const addErrorClass = elementIds => {
  document.querySelectorAll(`#${elementIds.join(', #')}`).forEach(element => {
    element.classList.add('has-error')
  })
}

export const formatStringForID = string =>
  typeof string !== 'string'
    ? ''
    : string
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')

export const PW_REQUIREMENTS = [
  { key: 'isEightChar', text: '8 characters minimum' },
  { key: 'hasUppercase', text: 'one uppercase letter' },
  { key: 'hasLowercase', text: 'one lowercase letter' },
  { key: 'hasNum', text: 'one number' },
  { key: 'hasSpecialChar', text: 'one special character (e.g., ~!@#$%^&*?)' },
]

export function getPasswordError(pw1, pw2, dispatch) {
  let message = ''
  const errors = {}
  if (pw1 !== pw2) {
    message = 'Passwords do not match'
    if (dispatch && message) {
      dispatch(notyActions.showError({ text: generateNotyMessage(message, false) }))
    }
    errors.pwNoMatchError = 'Passwords do not match'
  }
  if (!validatePassword(pw1)) {
    message = 'Please make sure your password meets all of the required criteria.'
    if (dispatch && message) {
      dispatch(notyActions.showError({ text: generateNotyMessage(message, false) }))
    }
    errors.pwMeetCriteriaError = 'Please make sure your password meets all of the required criteria.'
  }
  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    return errors
  }
  return null
}

export const _secondsToTimeString = seconds => {
  if (isNaN(seconds)) return ''
  const breaks = [60, 60, 24, 7]
  const strings = ['second', 'minute', 'hour', 'day', 'week']
  let time = seconds
  let i
  for (i = 0; i < breaks.length; i++) {
    if (time >= breaks[i]) time /= breaks[i]
    else break
  }
  time = Math.floor(time)
  time = `${time} ${strings[i]}${time > 1 ? 's' : ''}`
  return time
}

export const runNTimes = (num, cb) => {
  for (let i = 0; i < num; i++) {
    cb()
  }
}

/**
 * Downloads the file on the user's browser
 * @param {blob} blob
 * @param {string} name
 */

export function downloadBlob(blob, name, fileNameFromSystem) {
  const url = URL.createObjectURL(blob)
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('target', '_blank')
  element.style.display = 'none'
  if (fileNameFromSystem) {
    element.setAttribute('download', fileNameFromSystem)
  } else {
    element.setAttribute('download', name)
  }
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  URL.revokeObjectURL(url)
}

export const downloadPDF = (url, name = 'pdfFile.pdf') => {
  fetch(url).then(response => {
    response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob)
      // Setting various property values
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = name
      alink.click()
    })
  })
}

export const _getNavBarOffset = () => {
  const navbar = document.getElementsByClassName('navbar')[0]
  const offset = navbar ? navbar.offsetHeight : 0
  return offset
}

// this function calculates offsets for sticky elements for use in componenetDidMount
export const calculateOffsets = (className = 'sticky') => {
  const offsetArr = []
  const navBarOffset = _getNavBarOffset() || 60
  let totalPrevOffsets = 0
  const elements = document.getElementsByClassName(className)
  const elementsArr = [...elements]
  elementsArr.forEach((el, i) => {
    if (i === 0) {
      offsetArr.push(navBarOffset)
      totalPrevOffsets += navBarOffset
    } else {
      const iterOffset = elementsArr[i - 1].offsetHeight + totalPrevOffsets
      offsetArr.push(iterOffset)
      totalPrevOffsets = iterOffset
    }
  })
  return offsetArr
}

export const useOffsets = (className = 'sticky', updateOnVariable) => {
  const [offsets, setOffsets] = useState(null)
  useEffect(() => {
    setOffsets(calculateOffsets(className))
  }, [updateOnVariable])
  return offsets
}

export const addUniqToArr = (arr, el) => {
  const result = arr.slice()
  if (!result.includes(el)) result.push(el)
  return result
}

export const uniqueArr = arr => {
  return arr.filter((val, idx, _arr) => {
    return _arr.indexOf(val) === idx
  })
}

export const hasRemainder = (dividend, divisor) => {
  return dividend % divisor > 0
}

export const returnPercentage = (dividend, divisor, decimalPts = 0) => {
  if (hasRemainder(dividend, divisor)) {
    return ((dividend / divisor) * 100).toFixed(decimalPts)
  }
  return (dividend / divisor) * 100
}

/**
 * When creating sites with special characters in the name, we will parse the non-word
 * characters in double underscore delimited code points.
 * ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/codePointAt
 *
 * This will create site pathnames that are usable in the backend,
 *  as pathnames are restricted to word characters
 * ref: https://www.w3schools.com/jsref/jsref_regexp_wordchar_non.asp
 *
 * We then parse the underscore delimited code points back to the special characters in the
 * admin panel to be displayed.
 */

const convertCharToCodePoint = char => {
  if (!char) return ''
  const result = char.codePointAt(0)
  return `__${result}__`
}

const convertCodePointToChar = codePointWithUnderscores => {
  let resultStr = codePointWithUnderscores.replaceAll(/[_]/g, '')
  return String.fromCodePoint(resultStr)
}

// Non-word chars are everything that are not letters, numbers or underscores
const findNonWordChars = string => {
  return generateUniqArr(string.match(/\W/g))
}

// Find code points that are surrounded by '___' and '___'
const codePointsInString = string => {
  return generateUniqArr(string.match(/__[A-Za-z0-9]+__/g))
}

export const parseSpecialCharactersOut = (string = '') => {
  let resultStr = string.replace(/[_@~`!#$%^&*+=[\];|":?<>{}\\]/g, '')
  const nonWordChars = findNonWordChars(resultStr)
  nonWordChars.forEach(char => {
    resultStr = resultStr.replaceAll(char, convertCharToCodePoint(char))
  })

  resultStr = resultStr.replace(/[\/]/g, 'FORWARDSLASH')
  resultStr = resultStr.replace(/[-]/g, 'DASH')
  resultStr = resultStr.replace(/[']/g, 'APOSTROPHE')
  resultStr = resultStr.replace(/[,]/g, 'COMMA')
  resultStr = resultStr.replace(/[.]/g, 'PERIOD')
  resultStr = resultStr.replace(/[(]/g, 'OPENPARENS')
  resultStr = resultStr.replace(/[)]/g, 'CLOSEPARENS')
  resultStr = resultStr.replace(/[‑]/g, 'SMALLHYPHEN')
  return resultStr
}

export const parseSpecialCharactersIn = (string = '') => {
  let resultStr = string.slice()
  const encodedChars = codePointsInString(resultStr)
  encodedChars.forEach(encodedChar => {
    resultStr = resultStr.replaceAll(encodedChar, convertCodePointToChar(encodedChar))
  })

  resultStr = resultStr.replace(/[_]/g, ' ')
  resultStr = resultStr.replace(/FORWARDSLASH/g, '/')
  resultStr = resultStr.replace(/DASH/g, '-')
  resultStr = resultStr.replace(/APOSTROPHE/g, "'")
  resultStr = resultStr.replace(/COMMA/g, ',')
  resultStr = resultStr.replace(/PERIOD/g, '.')
  resultStr = resultStr.replace(/OPENPARENS/g, '(')
  resultStr = resultStr.replace(/CLOSEPARENS/g, ')')
  resultStr = resultStr.replace(/SMALLHYPHEN/g, '‑')
  return resultStr
}

const alphabet = '_01234567989abcdefghijklmnopqrstuvwxyz'

export const sortFunction = (el1, el2) => {
  const index_a = alphabet.indexOf(el1[0])
  const index_b = alphabet.indexOf(el2[0])

  if (index_a === index_b) {
    if (el1 < el2) {
      return -1
    }
    if (el1 > el2) {
      return 1
    }
    return 0
  }
  return index_a - index_b
}

export const returnSortedObjArrByKey = (objArr, key, isCaseInsensitive = true) => {
  return objArr.sort((a, b) => {
    let x = a[key]
    let y = b[key]
    if (isCaseInsensitive) {
      x = x.toLowerCase()
      y = y.toLowerCase()
    }
    if (x < y) return -1
    if (x > y) return 1
    return 0
  })
}
export const getBaseSiteIdFromStudy = study => {
  const { currentStudy } = study
  const { base_site_ids } = currentStudy
  return base_site_ids?.[0]
}

export const getFirstStudyID = userState => {
  if (!Object.keys(userState) || !userState.permissions || !Object.keys(userState.permissions)) return 0

  const firstStudyID = Object.keys(userState.permissions)[0]
  return firstStudyID
}

export const fileTypeRegex = /[^\.]+$/gm

export const onDoubleClick = (el, singleClickFunc, doubleClickFunc) => {
  if (el.getAttribute('data-dblclick') === null) {
    el.setAttribute('data-dblclick', 1)
    setTimeout(() => {
      if (el.getAttribute('data-dblclick') === '1') {
        singleClickFunc()
      }
      el.removeAttribute('data-dblclick')
    }, 300)
  } else {
    el.removeAttribute('data-dblclick')
    doubleClickFunc()
  }
}

//
// checks if arrays have the same element, irrespective of order
//
export const arraysContainSameElements = (arr1, arr2) => {
  if (!arr1 || !arr2) return false
  const set = new Set(arr2)
  if (arr1.length !== arr2.length) return false
  return arr1.every(value => set.has(value))
}

export const convertToLocalizedNumber = (num, locale, usd = false) => {
  if (usd) {
    return num.toLocaleString(locale, { style: 'currency', currency: 'USD' })
  }
  return num.toLocaleString(locale)
}

export const stripHtmlTags = string => {
  return string.replace(/<[^>]*>?/gm, '')
}

export const arrsAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

/**
 * Returns an array of user permissions for study with the highest role
 * `let highestRoleId = Number.MAX_SAFE_INTEGER` is required for setting maximum available number as the lowest role
 * If user has no permissions, an empty array will be returned
 */
export const getHighestUser = ({ user: userState, getRoleName = false, getRoleId = false }) => {
  let highestRoleId = Number.MAX_SAFE_INTEGER
  let permissions = []
  let roleName = 'Enroller'
  let roleId = 7

  Object.keys(userState.permissions).some(key => {
    if (userState.permissions[key].role_id < highestRoleId) {
      highestRoleId = userState.permissions[key].role_id
      permissions = userState.permissions[key].scope
      roleName = userState.permissions[key].name
      roleId = userState.permissions[key].role_id
    }
    return highestRoleId === ROLE_NAME_ID_MAP.Root
  })
  if (getRoleId) return roleId
  if (getRoleName) return roleName
  return permissions
}

export const getUserRole = userState => {
  const firstStudyID = getFirstStudyID(userState)
  if (firstStudyID === 0) return ''
  return userState.permissions[firstStudyID].name
}

export const getUserRoleId = (userState, studyID) => {
  if (studyID) {
    const userPermission = userState.permissions[studyID]
    if (userPermission) return userPermission.role_id
    return 7
  }
  const firstStudyID = getFirstStudyID(userState)
  if (firstStudyID === 0) return 7
  return userState.permissions[firstStudyID].role_id
}

export const getUserRoleByStudy = (userState, studyID) => {
  const userPermission = userState.permissions?.[studyID]
  if (userPermission) return userPermission.name
  return 'Enroller'
}

export const getUserRoleIdByStudy = (userState, studyID) => {
  const userPermission = userState?.permissions?.[studyID]
  if (userPermission) return userPermission.role_id
  return USER_ROLE_ID_MAP.enroller
}

export const getUserScope = (userState, studyID) => {
  const firstStudyID = getFirstStudyID(userState)
  if (studyID) {
    const selectedStudy = userState.permissions[studyID]
    if (!selectedStudy) return []
    return selectedStudy.scope
  }
  if (firstStudyID === 0) return []
  return userState.permissions[firstStudyID].scope
}

export const getUserScopeByStudy = (userState, study = {}) => {
  const { id: studyID } = study
  /**
   * If a study has custom roles, which are user types with certain permissions
   * codes added or removed, we will return those custom permission scopes.
   *
   * If not, the expected scopes will be returned.
   */
  if (study.custom_roles) {
    const currentRole = userState.permissions[studyID]?.role_id
    const customScope = study?.custom_roles[currentRole]?.scopes
    if (customScope) return customScope.split('|')
  }
  return userState.permissions[studyID]?.scope
}

/**
 * Returns true or false if the feature key is inside the config object and is enabled
 * @param {Object} config study config object (can not be undefined)
 * @param {Array} featuresArr feature array with the keys of the desired path to seek 'enabled'
 * How to use it: For example with this 'config' object:
 * config: { communication: { admin: { enabled: false, }, clinro: { enabled: true, } } }
 * The 'featuresArr' should be like this: ['communication', 'admin']
 * Then the 'newObj will be: { enabled: false }
 * Finally 'getEnabled' function will return enabled value
 */
export const getStudyFeature = (config, featuresArr) => {
  const hasFeature = config[featuresArr[0]]
  if (hasFeature) {
    const getEnabled = obj => obj.enabled
    let isEnabled = false
    if (featuresArr.length > 1) {
      let newObj = hasFeature
      for (let i = 1; i < featuresArr.length; i++) {
        newObj = newObj[featuresArr[i]]
      }
      isEnabled = getEnabled(newObj)
    } else {
      isEnabled = getEnabled(hasFeature)
    }
    return isEnabled
  } else return true
}

/**
 * Returns true or false if the feature keys is inside the config object
 * Can check a list of fields inside config with deep nested if at least one field is false will return false
 * @param {Object} config config object (can be only an object)
 * @param {Array} featuresArr feature array with the keys of the desired path to seek (can have strings && objects)
 * How to use it: For example with this 'config' object:
 * config: { communication: { admin: { enabled: false, }, clinro: { } } }
 * The 'featuresArr' should be like this: ['clinro', {name: 'admin', props: ['enabled']}]
 * Then the function will return false because admin.enabled = false, if it was true the result would be true
 */

export const checkConfigFeatures = (config, featuresArr, redirectIfEnabled = false, nullMeansEnabled = false) => {
  if (!config || Object.entries(config).length === 0 || !Array.isArray(featuresArr)) return false
  for (let i = 0; i < featuresArr.length; i++) {
    if (typeof featuresArr[i] === 'string') {
      if (!config[featuresArr[i]]) {
        return false
      }
    } else if (nullMeansEnabled && !config[featuresArr[i].name]) {
      /**
       * This conditional block allows for the config checking where if something doesn't exist, then the feature
       * exist.
       *
       * E.g., for configs that designate whether something is disabled, the lack of this particular config
       * means that the feature does exist.
       */
      return true
    } else if (
      !featuresArr[i].name ||
      !Array.isArray(featuresArr[i].props) ||
      !config[featuresArr[i].name] ||
      (!redirectIfEnabled &&
        !checkConfigFeatures(config[featuresArr[i].name], featuresArr[i].props, redirectIfEnabled, nullMeansEnabled))
    ) {
      return false
    } else if (redirectIfEnabled) {
      return !checkConfigFeatures(
        config[featuresArr[i].name],
        featuresArr[i].props,
        redirectIfEnabled,
        nullMeansEnabled,
      )
    }
  }
  return true
}

export const hasPiiRole = currentUserRole =>
  Object.values(USER_ROLES_WITH_PII_NAME_ID_MAP)
    .map(role => role.name)
    .includes(currentUserRole)

export const isSiteAdmin = currentUserRole => SITE_ROLE_LIST.includes(currentUserRole)

export const onEnterPress = (event, callback) => {
  const code = event.keyCode || event.which
  // 13 is the Enter key keycode
  if (code === 13) {
    callback()
  }
}

export const obfuscateString = (numCharsToShow, string) => {
  const stringToShow = string.slice(string.length - numCharsToShow)
  return `************${stringToShow}`
}

export function debounce(cb, delay = 300) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => cb.apply(this, args), delay)
  }
}

export const throttleWithRunOfLastCall = (func, limit) => {
  let lastFunc
  let lastRan
  return (...args) => {
    const context = this
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const throttle = (func = () => {}, duration) => {
  let shouldWait = false
  return (...args) => {
    if (!shouldWait) {
      func.apply(this, args)
      shouldWait = true
      setTimeout(() => {
        shouldWait = false
      }, duration)
    }
  }
}

export const hasScrollbar = () => {
  // The Modern solution
  if (typeof window.innerWidth === 'number') return window.innerWidth > document.documentElement.clientWidth

  // rootElem for quirksmode
  const rootElem = document.documentElement || document.body

  // Check overflow style property on body for fauxscrollbars
  let overflowStyle

  if (typeof rootElem.currentStyle !== 'undefined') overflowStyle = rootElem.currentStyle.overflow

  overflowStyle = overflowStyle || window.getComputedStyle(rootElem, '').overflow

  // Also need to check the Y axis overflow
  let overflowYStyle

  if (typeof rootElem.currentStyle !== 'undefined') overflowYStyle = rootElem.currentStyle.overflowY

  overflowYStyle = overflowYStyle || window.getComputedStyle(rootElem, '').overflowY

  const contentOverflows = rootElem.scrollHeight > rootElem.clientHeight
  const overflowShown = /^(visible|auto)$/.test(overflowStyle) || /^(visible|auto)$/.test(overflowYStyle)
  const alwaysShowScroll = overflowStyle === 'scroll' || overflowYStyle === 'scroll'

  return (contentOverflows && overflowShown) || alwaysShowScroll
}

export const convertFileTypeArrToText = fileTypeArr => {
  const newArr = fileTypeArr.map(fileTypeStr => {
    return fileTypeStr.substr(1).toUpperCase()
  })
  if (newArr.length === 2) return `${newArr.join(' and ')} only`
  return `${newArr.join(', ')} only`
}

export const roundNumber = (value, decimals) => {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
}

export const convertToMB = bytes => {
  return roundNumber(bytes / 1000000, 2)
}

export const generateUniqArr = arr => {
  return [...new Set(arr)]
}

export function isObject(value) {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

export const isFunction = value => typeof value === 'function'

const randomString = length => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const getRandomId = () => {
  const possible = 'abcdefghijklmnopqrstuvwxyz'
  return (
    possible.charAt(Math.floor(Math.random() * possible.length)) +
    h64(randomString(12), 0x586fc)
      .toString(16)
      .substring(0, 6)
  )
}

export const getFloor = number => Math.floor(number)

/**
 * Sort an Object Array
 * @param {String} key is the object key to sort the Object Array
 * @param {String} order it can be null or 'desc'
 */
export const compareValues = (key, order = 'asc') => (a, b) => {
  if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
    // property doesn't exist on either object
    return 0
  }

  const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
  const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

  let comparison = 0
  if (varA > varB) {
    comparison = 1
  } else if (varA < varB) {
    comparison = -1
  }
  return order === 'desc' ? comparison * -1 : comparison
}

export const makeMultipleOf = (x, multiple) => getFloor(x / multiple) * multiple + multiple

export const hasValue = value => {
  if (Array.isArray(value)) {
    return value.length > 0
  }
  return !!value
}

export const isArray = val => {
  return Array.isArray(val)
}

export const setPageTitle = val => {
  document.title = val || 'Admin Panel'
}

export const handleNaN = e => cb => {
  if (e.target.value === '') {
    cb()
  }
}

export const scrollTo = ({ element, offset }) => {
  const elementTop = element.getBoundingClientRect().top
  const bodyTop = document.body.getBoundingClientRect().top

  const top = elementTop - bodyTop - offset
  if (element) {
    window.scrollTo({ behavior: 'smooth', top })
  }
}

export const disableScope = ({ study, user, scope }) => {
  const { currentStudy } = study
  const customRoles = currentStudy?.custom_roles
  if (!!customRoles) {
    const userRoleId = getUserRoleId(user, currentStudy.id)
    const roleFilter = Object.keys(customRoles).filter(role => role == userRoleId)
    if (roleFilter.length) {
      return !customRoles[roleFilter].scopes.split('|').includes(scope)
    }
  }
  return false
}

export const truncateString = (text, maxLength, extra = '') =>
  text.length > maxLength ? `${text.slice(0, maxLength)}${extra}` : text
