import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from 'components/UIElements'
import { FA_ICON_MAP } from 'utils/constants'
import '../styles/d3/documentlist.scss'
import STRINGS from 'utils/strings'

const REPORT_TYPES = {
  ediary: 'Diary Responses',
  audio_files: 'Audio Files',
}

const DocumentList = props => {
  const {
    list = [],
    canWrite = false,
    onAdd = () => {},
    addLink = '',
    onDelete = () => {},
    onDownload = () => {},
    onOpen = () => {},
    noList = false,
    emptyText = STRINGS.noDocuments,
    useLetterThumbnail = false,
    showId = false,
    dataInbox = false,
  } = props

  const renderButtonContent = ({ type, name, thumb_url }) => {
    if (thumb_url) return <img src={thumb_url} alt={`From url: ${thumb_url}`} />
    if (useLetterThumbnail) return <span className='thumbnail-placeholder letter'>{name[0].toUpperCase()}</span>
    return <i className={`${FA_ICON_MAP[type]} thumbnail-placeholder`} />
  }

  const renderList = () => {
    return list.map(listItem => {
      const {
        name,
        id,
        report_type: reportType,
        thumb_url,
        type,
        metadata = {},
        participants = [],
        processed,
      } = listItem
      const { is_external } = metadata
      const _onDelete = () => {
        onDelete(listItem)
      }
      const _onDownload = () => {
        onDownload(listItem)
      }

      const infoIconContent =
        showId && participants?.length ? `${STRINGS.participantIds} ${participants.join(', ')}` : ''

      const reportTypeData = REPORT_TYPES[reportType]

      const customReportType = reportType?.split('_').join(' ')

      return (
        <div
          key={`${id}-${name}`}
          className={`document-button-wrapper border-radius${is_external ? ' is-external' : ''}`}>
          {is_external && (
            <div className='external-overlay flexed center-justified center-aligned'>
              <i className={`${FA_ICON_MAP.externalLink} pos abs external-icon`} />
            </div>
          )}
          <Button
            noStyling
            type='button'
            disabled={dataInbox && !processed}
            className={`document-button ${
              dataInbox && (reportTypeData || customReportType) ? 'document-button-flexed' : ''
            }`}
            onClick={() => onOpen({ ...listItem })}>
            {renderButtonContent({ thumb_url, type, name })}
            {dataInbox && (reportTypeData || customReportType) ? (
              <div className='document-name'>{`${STRINGS.reportType} ${reportTypeData || customReportType}`}</div>
            ) : null}
          </Button>
          <div className='document-info flexed '>
            <div className='icon-name flexed'>
              <i className={FA_ICON_MAP[type]} />
              <div className='document-name'>{name || showId ? `${STRINGS.requestId} ${id}` : ''}</div>
              {!!infoIconContent ? (
                <Popup
                  hover
                  dark
                  position='bottom'
                  className='info-tooltip'
                  trigger={<i className='fas fa-info-circle' />}>
                  {infoIconContent}
                </Popup>
              ) : null}
            </div>
            <Popup
              trigger={
                <Button
                  id={`${id}-document-popup-trigger`}
                  noStyling
                  className={`${FA_ICON_MAP.verticalEllipsis} document-popup-trigger`}
                />
              }
              noPointer
              dark
              position='bottom'
              align='right'>
              <Button
                content={STRINGS.download}
                disabled={dataInbox && !processed}
                iconBefore={FA_ICON_MAP.fileDownload}
                onClick={_onDownload}
              />
              {canWrite && <Button content={STRINGS.delete} iconBefore={FA_ICON_MAP.trash} onClick={_onDelete} />}
            </Popup>
          </div>
        </div>
      )
    })
  }
  const renderAddButton = () => {
    return (
      <div className='document-button-wrapper border-radius'>
        <Button noStyling className='document-button add-button' onClick={onAdd} to={addLink}>
          <>
            <i className={FA_ICON_MAP.plus} />
            <div>Add New</div>
          </>
        </Button>
      </div>
    )
  }

  return (
    <div className='document-list'>
      {noList && !canWrite ? <span>{emptyText}</span> : renderList()}
      {canWrite && renderAddButton()}
    </div>
  )
}

DocumentList.propTypes = {
  addLink: PropTypes.string,
  canWrite: PropTypes.bool,
  emptyText: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  noList: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onOpen: PropTypes.func,
  useLetterThumbnail: PropTypes.bool,
}

export default DocumentList
