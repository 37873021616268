import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { fetchUser } from 'store/user'
import { injectReducer } from 'store/reducers'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import { fetchStudy } from '../../../../../CreateStudy/modules/CreateStudy'
import EmbeddedInsights from './container/EmbeddedInsightsContainer'
import dashboardReducer, { fetchSisenseUrl } from '../../modules/StudyDashboard'

// Sync route definition
export default store => ({
  path: '/studies/:studyID/compliance/iframe/:dashboard/:vendor',
  exact: true,
  getIndexRoute(location, cb) {
    store.dispatch(setSubroute('iframe'))
    // Return Component
    injectReducer(store, {
      key: 'studyDashboard',
      reducer: dashboardReducer,
    })
    cb(null, {
      getComponent(nextState, _cb) {
        require.ensure(
          [],
          () => {
            const { params } = nextState
            const { studyID, dashboard, vendor } = params

            const { currentStudy } = store.getState().study
            const { config } = currentStudy
            store.dispatch(fetchStudy(studyID))

            store.dispatch(setSubroute(config?.insights ? 'iframe' : 'compliance'))
            store.dispatch(fetchUser())
            setPageTitle(`Embedded Insights | Study ${studyID} | ${currentStudy.platform_name}`)
            // validate if we have data in insiights after fetching the sisense post request
            store.dispatch(
              fetchSisenseUrl({
                dashboard: { dashboard_name: dashboard, vendor_type: vendor },
                studyID,
              }),
            )

            _cb(
              null,
              UserIsAuthenticated(
                UserHasValidPassword(UserHasScope(['ra', 'rp', 'rd'], EmbeddedInsights, false, studyID)),
              ),
            )
          },
          '/studies/:studyID/compliance/iframe/:dashboard/:vendor',
        )
      },
    })
  },
})
