import React from 'react'
import STRINGS from 'utils/strings'

import '../stylesheets/EmbeddedInsights.scss'

const EmbeddedInsights = ({ sisenseUrl, dashboard }) => {
  return (
    <div className='embedded-insights page'>
      <div className='selected-button-list '>
        <h4>{STRINGS.datacubedInsights}</h4>
      </div>
      <iframe className='embedded-iframe' src={sisenseUrl} id='embedded-iframe' allowFullScreen />
    </div>
  )
}

export default EmbeddedInsights
