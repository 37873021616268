import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DocumentList, Loader, SearchInput } from 'components/UIElements'
import { getStrictRegexFromTerm } from 'utils/search'
import STRINGS from 'utils/strings'
import DateFilter from '../../../../Instruments/routes/InstrumentsPage/components/DiaryDataDownload/DateFilter'
import moment from 'moment'

const SEARCHABLE_KEYS = ['id']

const DataInboxPage = props => {
  const { loading, dataInboxMaterial = {}, fetchDataInboxUrl, studyId, fetchDataInbox } = props
  const { data_inbox: dataInbox = [] } = dataInboxMaterial
  const [date, setDate] = useState({ from: '', to: '', allDate: false })
  const [dateError, setDateError] = useState({ from: '', to: '', allDate: false })

  useEffect(() => {
    const today = moment().toDate()
    const monthAgo = moment()
      .subtract(1, 'months')
      .toDate()

    setDate({ from: monthAgo, to: today })
  }, [])

  useEffect(() => {
    if (date?.from && date?.to) {
      fetchDataInbox({ studyId, from: date?.from, to: date?.to })
    }
    if (date?.allDate) {
      fetchDataInbox({ studyId, from: '', to: '' })
    }
  }, [date])

  const getFilteredList = list => {
    const { searchTerm } = props
    const searchKeys = SEARCHABLE_KEYS
    if (!searchTerm) return list
    const regex = getStrictRegexFromTerm(searchTerm)
    return list.filter(item => {
      for (let i = 0; i < searchKeys.length; i++) {
        const key = searchKeys[i]
        const value = String(item?.[key])
        if (value?.match(regex)) {
          return true
        }
      }
      return false
    })
  }

  return (
    <div className='training data-inbox page'>
      <div className='flexed-header end-justified'>
        <DateFilter date={date} onSetDate={setDate} dateError={dateError} setDateError={setDateError} />
        <SearchInput placeholder={STRINGS.searchEllipsis} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className='bold'>{STRINGS.dataInbox}</h4>
          <DocumentList
            list={getFilteredList(dataInbox)}
            onDownload={item => {
              fetchDataInboxUrl(item?.id)
            }}
            onOpen={item => {
              fetchDataInboxUrl(item?.id)
            }}
            noList={!dataInbox.length}
            emptyText={STRINGS.noDataInbox}
            showId
            dataInbox
          />
        </>
      )}
    </div>
  )
}

DataInboxPage.propTypes = {
  canWrite: PropTypes.bool,
  deleteTrainingMaterial: PropTypes.func,
  loading: PropTypes.bool,
  minimizeOverlay: PropTypes.func,
  openOverlay: PropTypes.func,
  resetTraining: PropTypes.func,
  searchTerm: PropTypes.string,
  studyId: PropTypes.number,
  dataInboxMaterial: PropTypes.shape({ data_inbox: PropTypes.array }),
}

export default DataInboxPage
